import { Types } from '@progymedia/gatsby-theme-boutik-ecommerce/src/engine/apis/magento2';

import {
    GetSimpleProductOptionsBySkuQuery,
    GetSimpleProductOptionsBySkuQueryVariables,
    GetSimpleProductOptionsBySkusQueryVariables,
    GetSimpleProductOptionsBySkusDocument,
    GetSimpleProductOptionsBySkuDocument,
    GetSimpleProductOptionsBySkusQuery,
    UpdateCartItemsDocument,
    CreateGuestCartMutation,
    CreateGuestCartMutationVariables,
    CreateGuestCartDocument,
    GetCartContentDocument,
    GetCartContentQuery,
    AddProductsFromQuoteIdToCartDocument,
} from '../context/cart/graphql';

import {
    AddCartMutation,
    UpdateCartMutation,
    CartOperations,
} from '../interfaces/operations/cartOperations';

import { ApolloCache, ApolloClient, FetchResult } from '@apollo/client';
import { AddSimpleProductsToCartDocument } from '../context/cart/graphql';
import { AddSimpleProductsToCartMutation } from '../context/cart/graphql';

export class MagentoCartOperations implements CartOperations {
    private apolloClient: ApolloClient<object>;
    private updateApolloCache: (cache: ApolloCache<any>) => void;

    constructor(
        apolloClient: ApolloClient<object>,
        updateApolloCache: (cache: ApolloCache<any>) => void
    ) {
        this.apolloClient = apolloClient;
        this.updateApolloCache = updateApolloCache;
    }

    public async updateCartItemsMutation(
        cartMutation: UpdateCartMutation
    ): Promise<any> {
        const result = await this.apolloClient.mutate({
            mutation: UpdateCartItemsDocument,
            variables: cartMutation,
        });
        await this.updateApolloCache(this.apolloClient.cache);
        return result;
    }

    public async addProductsToCartMutation(
        cartMutation: AddCartMutation
    ): Promise<any> {
        const result = await this.apolloClient.mutate({
            mutation: AddProductsFromQuoteIdToCartDocument,
            variables: cartMutation,
        });
        await this.updateApolloCache(this.apolloClient.cache);
        return result;
    }

    public async addSimpleProductsToCartMutation(
        addSimpleProductsToCart: Types.AddSimpleProductsToCartInput
    ): Promise<FetchResult<AddSimpleProductsToCartMutation>> {
        const result = await this.apolloClient.mutate({
            mutation: AddSimpleProductsToCartDocument,
            variables: { input: addSimpleProductsToCart },
        });
        await this.updateApolloCache(this.apolloClient.cache);
        return result;
    }

    public async createGuestCartMutation(): Promise<any> {
        const result = await this.apolloClient.mutate<
            CreateGuestCartMutation,
            CreateGuestCartMutationVariables
        >({ mutation: CreateGuestCartDocument, fetchPolicy: 'no-cache' });
        await this.updateApolloCache(this.apolloClient.cache);
        return result;
    }

    public async getCartQuery(cartId: string): Promise<GetCartContentQuery> {
        return await this.apolloClient
            .query({
                query: GetCartContentDocument,
                variables: {
                    cart_id: cartId,
                },
            })
            .then((result) => result.data)
            .catch((e) => {
                throw e;
            });
    }

    public async GetSimpleProductOptionsBySkuQuery(
        sku: string
    ): Promise<GetSimpleProductOptionsBySkuQuery | null> {
        const result = await this.apolloClient.query<
            GetSimpleProductOptionsBySkuQuery,
            GetSimpleProductOptionsBySkuQueryVariables
        >({
            query: GetSimpleProductOptionsBySkuDocument,
            variables: { sku },
        });
        return result.data;
    }

    public async GetSimpleProductOptionsBySkusQuery(
        skus: string[]
    ): Promise<GetSimpleProductOptionsBySkusQuery | null> {
        const result = await this.apolloClient.query<
            GetSimpleProductOptionsBySkusQuery,
            GetSimpleProductOptionsBySkusQueryVariables
        >({
            query: GetSimpleProductOptionsBySkusDocument,
            variables: { skus },
        });
        return result.data;
    }
}
