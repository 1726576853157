import React from 'react';
import { ApolloCache, ApolloClient } from '@apollo/client';

import { MagentoReviewsHttpRepository } from '../../repositories/magentoReviewsHttpRepository';
import { ImageHttpRepository } from '../../repositories/imageHttpRepository';
import { BoutikEC2ProxyHttpRepository } from '../../repositories/boutikEC2ProxyHttpRepository';
import { MagentoInventoryHttpRepository } from '../../repositories/magentoInventoryHttpRepository';
import { AxiosService } from '../../services/axiosService';
import { ReviewsServiceImpl } from '../../services/reviewsServiceImpl';
import { ImageServiceImpl } from '../../services/imageServiceImpl';
import { OrdersServiceImpl } from '../../services/ordersServiceImpl';
import { InventoryServiceImpl } from '../../services/inventoryServiceImpl';
import { CartServiceImpl } from '../../services/cartServiceImpl';
import { MagentoCartOperations } from '../../operations/magentoCartOperations';
import { ProductServiceImpl } from '../../services/productServiceImpl';
import { MagentoProductOperations } from '../../operations/magentoProductOperations';

export type OtherBoutikService = any;

export type OtherBoutikServices = Record<string, OtherBoutikService>;

const initialize = () => {
    const services: OtherBoutikServices = {};

    services['BoutikEC2ProxyRepository'] = new BoutikEC2ProxyHttpRepository(
        new AxiosService(`/inquiry`)
    );

    services['ImageService'] = new ImageServiceImpl(
        new ImageHttpRepository(new AxiosService('', ' ' as string))
    );

    // le code store mfp_fr_ca est utilisé, mais le enpoint va repérer tout les stores (ca en, ca fr et us)
    services['ReviewsService'] = new ReviewsServiceImpl(
        new MagentoReviewsHttpRepository(
            new AxiosService(
                `/rest/mfp_fr_ca/V1`,
                process.env.GATSBY_MAGENTO_API_TOKEN as string
            )
        ),
        services['ImageService']
    );

    services['OrdersService'] = new OrdersServiceImpl(
        services['BoutikEC2ProxyRepository']
    );

    services['InventoryService'] = new InventoryServiceImpl(
        new MagentoInventoryHttpRepository(new AxiosService(`/inquiry`, '_'))
    );

    return services;
};

export const injectHookServices = (
    otherBoutikServices: OtherBoutikServices,
    apolloClient: ApolloClient<object>,
    updateApolloClientCache: (cache: ApolloCache<any>) => void
) => {
    otherBoutikServices['CartService'] = new CartServiceImpl(
        new MagentoCartOperations(apolloClient, updateApolloClientCache),
        otherBoutikServices['BoutikEC2ProxyRepository']
    );
    otherBoutikServices['ProductService'] = new ProductServiceImpl(
        new MagentoProductOperations(apolloClient, updateApolloClientCache)
    );
};

export const OtherBoutikServicesContext =
    React.createContext<OtherBoutikServices>(initialize());
