import { BoutikEC2ProxyRepository } from '../interfaces/repositories/boutikEC2ProxyRepository';
import { HttpService } from '../interfaces/services/httpService';
import {
    OrdersByIdArgs,
    Orders,
    OrdersArgs,
    OrdersByDateArgs,
    OrdersPaginated,
    OrderArgs,
    InvoicesByOrderIdArgs,
    ProductImageBySkuArgs,
} from '../interfaces/services/ordersService';
import { CustomerOrderDetails } from '../models/customerOrderDetails';
import { HttpResponse } from '../models/httpResponse';
import { MagentoCartQuote } from '../models/magentoCartQuote';

export class BoutikEC2ProxyHttpRepository implements BoutikEC2ProxyRepository {
    private httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async getOrdersByDate(
        ordersByDateArgs: OrdersByDateArgs
    ): Promise<OrdersPaginated | null> {
        const {
            fromDate,
            toDate,
            customerId,
            storeCode,
            currentPage,
            pageSize,
        } = ordersByDateArgs;
        return await this.httpService
            .get(
                `/order-by-date?from=${fromDate}&to=${toDate}&customerId=${customerId}&store=${storeCode}&currentPage=${currentPage}&pageSize=${pageSize}`
            )
            .then((response: HttpResponse) => {
                return response.status == 200
                    ? (response.data as OrdersPaginated)
                    : null;
            })
            .catch((error) => {
                throw error;
            });
    }

    async getOrdersById(
        ordersByIdArgs: OrdersByIdArgs
    ): Promise<OrdersPaginated | null> {
        const { orderId, customerId, storeCode, currentPage, pageSize } =
            ordersByIdArgs;
        return await this.httpService
            .get(
                `/order-by-command-number?commandNumber=${orderId}&customerId=${customerId}&store=${storeCode}&currentPage=${currentPage}&pageSize=${pageSize}`
            )
            .then((response: HttpResponse) => {
                return response.status == 200
                    ? (response.data as OrdersPaginated)
                    : null;
            })
            .catch((error) => {
                throw error;
            });
    }

    async getOrders(ordersArgs: OrdersArgs): Promise<Orders | null> {
        return await this.httpService
            .get(
                `/orders?customerId=${ordersArgs.customerId}&store=${ordersArgs.storeCode}`
            )
            .then((response: HttpResponse) => {
                return response.status == 200
                    ? (response.data as Orders)
                    : null;
            })
            .catch((error) => {
                throw error;
            });
    }

    async getOrder(orderArgs: OrderArgs): Promise<CustomerOrderDetails | null> {
        return await this.httpService
            .get(
                `/order-by-id?orderId=${orderArgs.orderId}&customerId=${orderArgs.customerId}&store=${orderArgs.storeCode}`
            )
            .then((response: HttpResponse) => {
                return response.status == 200 ? (response.data as any) : null;
            })
            .catch((error) => {
                throw error;
            });
    }

    async getInvoicesByOrderId(
        invoicesByOrderIdArgs: InvoicesByOrderIdArgs
    ): Promise<any | null> {
        return await this.httpService
            .get(
                `/invoices-by-order-id?orderId=${invoicesByOrderIdArgs.orderId}`
            )
            .then((response: HttpResponse) => {
                return response.status == 200 ? (response.data as any) : null;
            })
            .catch((error) => {
                throw error;
            });
    }

    async getProductImage(
        productImageBySkuArgs: ProductImageBySkuArgs
    ): Promise<any | null> {
        return await this.httpService
            .get(`/image/mfp_en_ca/${productImageBySkuArgs.sku}?base64=true`)
            .then((response: HttpResponse) => {
                return response.status == 200 ? (response.data as any) : null;
            })
            .catch((error) => {
                throw error;
            });
    }

    async getCartQuote(cartId: string): Promise<MagentoCartQuote | null> {
        return await this.httpService
            .get(`/get-cart-quote/${cartId}`)
            .then((response: HttpResponse) => {
                return response.status == 200
                    ? (response.data as MagentoCartQuote)
                    : null;
            })
            .catch((error: any) => {
                throw error;
            });
    }
}
