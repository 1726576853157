import { ProductService } from '../interfaces/services/productService';
import { ProductOperations } from '../interfaces/operations/productOperations';

export class ProductServiceImpl implements ProductService {
    private productOperations: ProductOperations;

    constructor(productOperations: ProductOperations) {
        this.productOperations = productOperations;
    }

    async getProductOptionPriceAndInventoryBySkus(
        skus: string[]
    ): Promise<any> {
        const pricesAndInventories =
            await this.productOperations.getPricesAndInventoriesQuery(skus);
        return pricesAndInventories;
    }
}
