import {} from '../context/cart/graphql';

import { ProductOperations } from '../interfaces/operations/productOperations';

import { ApolloCache, ApolloClient } from '@apollo/client';
import {
    ProductsPriceAndInventoriesDocument,
    ProductsPriceAndInventoriesQuery,
    ProductsPriceAndInventoriesQueryVariables,
} from '../context/store/graphql';

export class MagentoProductOperations implements ProductOperations {
    private apolloClient: ApolloClient<object>;
    private updateApolloCache: (cache: ApolloCache<any>) => void;

    constructor(
        apolloClient: ApolloClient<object>,
        updateApolloCache: (cache: ApolloCache<any>) => void
    ) {
        this.apolloClient = apolloClient;
        this.updateApolloCache = updateApolloCache;
    }

    public async getPricesAndInventoriesQuery(
        skus: string[]
    ): Promise<
        | NonNullable<ProductsPriceAndInventoriesQuery['products']>['items']
        | undefined
    > {
        const result = await this.apolloClient.query<
            ProductsPriceAndInventoriesQuery,
            ProductsPriceAndInventoriesQueryVariables
        >({
            query: ProductsPriceAndInventoriesDocument,
            variables: { skus },
        });
        await this.updateApolloCache(this.apolloClient.cache);

        return result.data.products?.items;
    }
}
