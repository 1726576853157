// This file was automatically generated by graphql-code-generator
// Changes to this file will be lost


import * as Types from '../../../engine/apis/magento2/types.generated.d';

import { gql } from '@apollo/client';
export type PriceAndStockFragment_BundleProduct_Fragment = { __typename?: 'BundleProduct', onlyXLeftInStock?: number | null, nextAvailableDate?: string | null, specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_ConfigurableProduct_Fragment = { __typename?: 'ConfigurableProduct', onlyXLeftInStock?: number | null, nextAvailableDate?: string | null, specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_DownloadableProduct_Fragment = { __typename?: 'DownloadableProduct', onlyXLeftInStock?: number | null, nextAvailableDate?: string | null, specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_GroupedProduct_Fragment = { __typename?: 'GroupedProduct', onlyXLeftInStock?: number | null, nextAvailableDate?: string | null, specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_SimpleProduct_Fragment = { __typename?: 'SimpleProduct', onlyXLeftInStock?: number | null, nextAvailableDate?: string | null, specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragment_VirtualProduct_Fragment = { __typename?: 'VirtualProduct', onlyXLeftInStock?: number | null, nextAvailableDate?: string | null, specialPriceEndDate?: string | null, specialPriceFromDate?: string | null, priceRange: { __typename?: 'PriceRange', minimumPrice: { __typename?: 'ProductPrice', regularPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null }, discount?: { __typename?: 'ProductDiscount', amountOff?: number | null, percentOff?: number | null } | null, finalPrice: { __typename?: 'Money', value?: number | null, currency?: Types.CurrencyEnum | null } } } };

export type PriceAndStockFragmentFragment = PriceAndStockFragment_BundleProduct_Fragment | PriceAndStockFragment_ConfigurableProduct_Fragment | PriceAndStockFragment_DownloadableProduct_Fragment | PriceAndStockFragment_GroupedProduct_Fragment | PriceAndStockFragment_SimpleProduct_Fragment | PriceAndStockFragment_VirtualProduct_Fragment;

export const PriceAndStockFragmentFragmentDoc = gql`
    fragment PriceAndStockFragment on ProductInterface {
  priceRange: price_range {
    minimumPrice: minimum_price {
      regularPrice: regular_price {
        value
        currency
      }
      discount {
        amountOff: amount_off
        percentOff: percent_off
      }
      finalPrice: final_price {
        value
        currency
      }
    }
  }
  onlyXLeftInStock: sbi_source_inventory
  nextAvailableDate: next_available_date
  specialPriceEndDate: special_to_date
  specialPriceFromDate: special_from_date
}
    `;