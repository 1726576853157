import { Types } from '@progymedia/gatsby-theme-boutik-ecommerce/src/engine/apis/magento2';
import { GetCartContentQuery } from '../context/cart/graphql';
import { MagentoCartQuoteDTOMapper } from '../dto/magentoCartQuoteDTOMapper';
import { CartOperations } from '../interfaces/operations/cartOperations';
import { BoutikEC2ProxyRepository } from '../interfaces/repositories/boutikEC2ProxyRepository';
import { CartService } from '../interfaces/services/cartService';
import { CartQuote, CartQuoteItem } from '../models/cartQuote';
import {
    CustomizableOption,
    ProductItem,
} from '../models/getSimpleProductOptionsBySkuQueryTypeModel';
import { SimpleProductWithOptions } from '../models/simpleProductWithOptions';
import { isStock } from '@progymedia/gatsby-theme-boutik-ecommerce/src/engine/PricesAndInventoriesCache';

export class CartServiceImpl implements CartService {
    private cartOperations: CartOperations;
    private boutikEC2ProxyRepository: BoutikEC2ProxyRepository;

    constructor(
        cartOperations: CartOperations,
        boutikEC2ProxyRepository: BoutikEC2ProxyRepository
    ) {
        this.cartOperations = cartOperations;
        this.boutikEC2ProxyRepository = boutikEC2ProxyRepository;
    }

    private isSkuMissing(
        currentCart: GetCartContentQuery,
        quoteItem: CartQuoteItem
    ) {
        return (
            currentCart?.cart?.items &&
            !currentCart?.cart?.items.some(
                (item) => item?.product?.sku == quoteItem.sku
            ) &&
            quoteItem.sku
        );
    }

    async createGuestCart(): Promise<void> {
        this.cartOperations.createGuestCartMutation();
    }

    async getCartByQuoteId(quoteId: string): Promise<CartQuote | null> {
        return await this.boutikEC2ProxyRepository
            .getCartQuote(quoteId)
            .then((cart) => {
                return cart && MagentoCartQuoteDTOMapper.toCartQuote(cart);
            })
            .catch(() => null);
    }

    async appendCartQuoteItem(
        cartId: string,
        cartQuote: CartQuote
    ): Promise<void> {
        await this.cartOperations
            .getCartQuery(cartId)
            .then(async (currentCart) => {
                if (cartQuote?.items) {
                    for (const quoteItem of cartQuote.items) {
                        if (this.isSkuMissing(currentCart, quoteItem)) {
                            await this.cartOperations.addProductsToCartMutation(
                                {
                                    cartId: cartId,
                                    sku: quoteItem.sku,
                                    quantity: quoteItem.qty,
                                }
                            );
                        }
                    }
                }
            });
    }

    private filterCheckoutOptions(product: ProductItem): ProductItem {
        if (product.__typename === 'SimpleProduct' && product.options) {
            const product_ = { ...product };

            product_.options = product.options.filter(
                (option) => option?.title === 'ACP'
            ) as CustomizableOption[];

            return product_;
        }

        return product;
    }

    private fillStock(product: ProductItem): ProductItem {
        product.inStock = isStock(product);
        return product;
    }

    async getIsCheckedOptionsBySku(sku: string): Promise<ProductItem | null> {
        const result =
            await this.cartOperations.GetSimpleProductOptionsBySkuQuery(sku);
        const product = result?.products?.items?.[0] ?? null;

        if (product) {
            return this.filterCheckoutOptions(product as ProductItem);
        }

        return product;
    }

    async getIsCheckedOptionsBySkus(
        skus: string[]
    ): Promise<ProductItem[] | null> {
        const result =
            await this.cartOperations.GetSimpleProductOptionsBySkusQuery(skus);
        const products = result?.products?.items ?? null;

        if (!products) {
            return null;
        }

        const filteredProducts = products
            .filter((product): product is ProductItem => product !== null)
            .map((product) => {
                const product_ = this.filterCheckoutOptions(
                    product as ProductItem
                );
                return this.fillStock(product_);
            });

        const validProducts = filteredProducts.filter(
            (product) => product.sku !== null && product.sku !== undefined
        );

        const sortedProducts = validProducts.sort((a, b) => {
            return (
                skus.indexOf(a.sku as string) - skus.indexOf(b.sku as string)
            );
        });

        return sortedProducts;
    }

    async addSimpleproductsWithOptions(
        cartId: string,
        simpleProductsWithOptions: SimpleProductWithOptions[]
    ): Promise<boolean> {
        const addSimpleProductsToCart = {
            cart_id: cartId,
            cart_items: simpleProductsWithOptions.map((i) => {
                return {
                    customizable_options: i.options,
                    data: {
                        sku: i.sku,
                        quantity: i.quantity,
                    } as Types.CartItemInput,
                };
            }),
        } as Types.AddSimpleProductsToCartInput;

        const result = await this.cartOperations
            .addSimpleProductsToCartMutation(addSimpleProductsToCart)
            .then((response) => !!response)
            .catch(() => {
                console.log('Error to add simple product');
                return false;
            });
        return result;
    }
}
